import React, { useState } from "react";
import { BsDiscord } from "react-icons/bs";
import Offcanvas from "react-bootstrap/Offcanvas";

const DiscordOffCanvas = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <BsDiscord onClick={() => handleShow()} className="discordButton" />
      <Offcanvas
        show={show}
        onClick={() => handleClose()}
        onHide={() => handleClose()}
        className="offcanvas"
      >
        <Offcanvas.Body>
          <iframe
            src="https://discord.com/widget?id=1165480754814386176&theme=dark"
            width="auto"
            height="500"
            className="discordiframe"
            title="discord-frame"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
          <div className="downloadBlurb">
            For faster access on android devices, you can install the
            application to your home screen through the settings icon in the top
            right!
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DiscordOffCanvas;
