import React, { createContext, useContext, useState, useEffect } from "react";
import helpers from "./helpers.js";

const ContextAll = createContext();

export function UseContextAll() {
  return useContext(ContextAll);
}

export function ContextAllProvider({ children }) {
  //init val
  const emptyDeposit = {
    Agricium: 0,
    Aluminum: 0,
    Beryl: 0,
    Bexalite: 0,
    Borase: 0,
    Copper: 0,
    Corundum: 0,
    Gold: 0,
    Hephaestanite: 0,
    Iron: 0,
    Laranite: 0,
    Quantainium: 0,
    Quartz: 0,
    Taranite: 0,
    Titanium: 0,
    Tungsten: 0,
  };
  //init val
  const uncheckedBoxes = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
  };

  //  STATES  //

  const [display, setDisplay] = useState("deposit calculator");

  //states for deposit calc
  const [deposit, setDeposit] = useState(emptyDeposit);
  const [total, setTotal] = useState(100);
  const [mass, setMass] = useState(0);
  const [assay, setAssay] = useState({});
  const [system, setSystem] = useState("Stanton");
  const [method, setMethod] = useState("Dinyx Solvention");
  const [isRefined, setIsRefined] = useState(false);
  const [refinery, setRefinery] = useState("");
  const [refineryRecs, setRefineryRecs] = useState({ best: {}, worst: {} });
  const [formState, setFormState] = useState({
    system: "Stanton",
    method: "Dinyx Solvention",
    refinery: "--Select Refinery--",
  });

  //states for price calc
  const [curMats, setCurMats] = useState([]);
  const [SCU, setSCU] = useState({});
  const [totalVal, setTotalVal] = useState(0);
  const [checkBoxState, setCheckBoxState] = useState(uncheckedBoxes);
  const [ifSaddleBags, setIfSaddleBags] = useState(false);
  const [yieldEstimateModifier, setYieldEstimateModifier] = useState("High");

  //  RESET FUNCTIONS  //

  const handleDepositReset = () => {
    //reset states
    setDeposit(emptyDeposit);
    setTotal(100);
    setMass(0);
    setAssay({});
    setSystem("Stanton");
    // setMethod("Dinyx Solvention");
    // setRefinery("");
    setRefineryRecs({ best: {}, worst: {} });
    //reset all the individual inputs
    document
      .querySelectorAll(".materialPercentInput")
      .forEach((el) => (el.value = ""));

    let newFormState = {
      system: "Stanton",
      method: "Dinyx Solvention",
      refinery: "--Select Refinery--",
    };

    setFormState(newFormState);
    //reset form with both drop downs for refinery and method
    // document.querySelector(".select-wrapper-refinery-and-method").reset();
  };

  const handlePriceReset = () => {
    setCurMats([]);
    setSCU({});
    setTotalVal(0);
    setIfSaddleBags(false);
    setYieldEstimateModifier("High");

    //clear form, too
    setCheckBoxState(uncheckedBoxes);
    // document
    //   .getElementsByName("material-checkbox")
    //   .forEach((el) => (el.checked = false));
  };

  //  EFFECT  //

  //effect for updating inert amount automatically
  useEffect(() => {
    let amountInert = 0;
    for (let val of Object.values(deposit)) {
      amountInert += val;
    }
    let newTotal = 100 - amountInert;
    setTotal(newTotal);
  }, [deposit]);

  useEffect(() => {
    setRefinery("");

    let copy = { ...formState };
    copy.refinery = "";
    copy.system = system;

    setFormState(copy);

    //eval rock
    let result = helpers.evaluate(mass, deposit, total);
    //get all station vals based on rock
    let refineryTotals = helpers.evalByRefinery(result);
    //get recommended stations based on all station vals & system
    let recommendations = helpers.getBestWorstRefineries(
      refineryTotals,
      system
    );

    setRefineryRecs(recommendations);
  }, [system]);

  // VALUES FOR EXPORT  //
  const values = {
    display,
    setDisplay,
    deposit,
    setDeposit,
    total,
    setTotal,
    mass,
    setMass,
    assay,
    setAssay,
    system,
    setSystem,
    method,
    setMethod,
    isRefined,
    setIsRefined,
    refinery,
    setRefinery,
    formState,
    setFormState,
    refineryRecs,
    setRefineryRecs,
    curMats,
    setCurMats,
    SCU,
    setSCU,
    totalVal,
    setTotalVal,
    checkBoxState,
    setCheckBoxState,
    ifSaddleBags,
    setIfSaddleBags,
    yieldEstimateModifier,
    setYieldEstimateModifier,
    handlePriceReset,
    handleDepositReset,
  };

  return <ContextAll.Provider value={values}>{children}</ContextAll.Provider>;
}
