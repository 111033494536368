//helper file for reference to values and calculation methods

const helpers = {
  materials: [
    "Quantainium",
    "Bexalite",
    "Gold",
    "Taranite",
    "Agricium",
    "Beryl",
    "Borase",
    "Hephaestanite",
    "Laranite",
    "Aluminum",
    "Copper",
    "Corundum",
    "Iron",
    "Quartz",
    "Titanium",
    "Tungsten",
    "Inert Material",
  ],

  /*
  materials: [
    "Agricium",
    "Aluminum",
    "Beryl",
    "Bexalite",
    "Borase",
    "Copper",
    "Corundum",
    "Gold",
    "Hephaestanite",
    "Iron",
    "Laranite",
    "Quantainium",
    "Quartz",
    "Taranite",
    "Titanium",
    "Tungsten",
    "Inert Material",
  ],
  */

  density: {
    Agricium: 241,
    Aluminum: 93,
    Beryl: 91,
    Bexalite: 547,
    Borase: 158,
    Copper: 304,
    Corundum: 137,
    Gold: 632,
    Hephaestanite: 106,
    Iron: 264,
    Laranite: 454,
    Quantainium: 685,
    Quartz: 90,
    Taranite: 356,
    Titanium: 151,
    Tungsten: 643,
    "Inert Material": 19,
  },

  scuValue: {
    Agricium: 2674,
    Aluminum: 312,
    Beryl: 2661,
    Bexalite: 7691,
    Borase: 3543,
    Copper: 407,
    Corundum: 370,
    Gold: 8097,
    Hephaestanite: 2668,
    Iron: 351,
    Laranite: 2904,
    Quantainium: 22250,
    Quartz: 415,
    Taranite: 7839,
    Titanium: 511,
    Tungsten: 397,
    "Inert Material": 0,
  },

  refineryMethodModifiers: {
    "Dinyx Solvention": 0.85,
    "Ferron Exchange": 0.85,
    "Cormack Method": 0.6,
    Electrostarolysis: 0.72,
    Pyrometric: 0.85,
    "Gaskin Process": 0.72,
  },

  refineryMethodModifiersSimple: {
    High: 0.85,
    Medium: 0.72,
    Low: 0.6,
  },

  //i hate this object :)
  refineryStationModifiers: {
    "HUR L1": {
      name: "Green Glade Station",
      system: "Stanton",
      modifiers: {
        Agricium: 0.92,
        Aluminum: 0.96,
        Beryl: 1,
        Bexalite: 0.98,
        Borase: 1.01,
        Copper: 0.95,
        Corundum: 0.95,
        Gold: 0.97,
        Hephaestanite: 1,
        Iron: 0.95,
        Laranite: 1.02,
        Quantainium: 1.02,
        Quartz: 1,
        Taranite: 1,
        Titanium: 1,
        Tungsten: 1.04,
      },
    },
    "HUR L2": {
      name: "Faithful Dream Station",
      system: "Stanton",
      modifiers: {
        Agricium: 0.98,
        Aluminum: 1,
        Beryl: 1.01,
        Bexalite: 1,
        Borase: 1,
        Copper: 0.97,
        Corundum: 1,
        Gold: 1.01,
        Hephaestanite: 1,
        Iron: 1,
        Laranite: 0.99,
        Quantainium: 1.02,
        Quartz: 1,
        Taranite: 0.97,
        Titanium: 1,
        Tungsten: 1,
      },
    },
    "CRU L1": {
      name: "Ambitious Dream Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 1.07,
        Bexalite: 0.94,
        Borase: 1,
        Copper: 1,
        Corundum: 1.07,
        Gold: 0.94,
        Hephaestanite: 0.98,
        Iron: 1.02,
        Laranite: 0.92,
        Quantainium: 1,
        Quartz: 1,
        Taranite: 1,
        Titanium: 0.99,
        Tungsten: 1.02,
      },
    },
    "ARC L1": {
      name: "Wide Forest Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 0.95,
        Beryl: 1.07,
        Bexalite: 1,
        Borase: 1,
        Copper: 1,
        Corundum: 0.96,
        Gold: 1,
        Hephaestanite: 0.96,
        Iron: 1.01,
        Laranite: 0.98,
        Quantainium: 1.03,
        Quartz: 1.11,
        Taranite: 0.94,
        Titanium: 1.05,
        Tungsten: 1,
      },
    },
    "ARC L2": {
      name: "Lively Pathway Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 1,
        Bexalite: 1.02,
        Borase: 1.02,
        Copper: 1.06,
        Corundum: 0.97,
        Gold: 1.07,
        Hephaestanite: 0.92,
        Iron: 1,
        Laranite: 1,
        Quantainium: 1.03,
        Quartz: 1,
        Taranite: 1,
        Titanium: 1.03,
        Tungsten: 0.94,
      },
    },
    "ARC L4": {
      name: "Faint Glen Station",
      system: "Stanton",
      modifiers: {
        Agricium: 0.96,
        Aluminum: 0.97,
        Beryl: 0.96,
        Bexalite: 1,
        Borase: 1,
        Copper: 0.96,
        Corundum: 0.91,
        Gold: 0.96,
        Hephaestanite: 0.95,
        Iron: 1,
        Laranite: 1,
        Quantainium: 1,
        Quartz: 0.98,
        Taranite: 1.05,
        Titanium: 0.98,
        Tungsten: 0.95,
      },
    },
    "MIC L1": {
      name: "Shallow Frontier Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1.08,
        Aluminum: 1.07,
        Beryl: 0.94,
        Bexalite: 1,
        Borase: 1,
        Copper: 1.04,
        Corundum: 1.02,
        Gold: 1.01,
        Hephaestanite: 1,
        Iron: 1,
        Laranite: 1.02,
        Quantainium: 1,
        Quartz: 0.97,
        Taranite: 1,
        Titanium: 1,
        Tungsten: 1,
      },
    },
    "MIC L2": {
      name: "Long Forest Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 0.92,
        Bexalite: 1.09,
        Borase: 0.97,
        Copper: 1.02,
        Corundum: 1.06,
        Gold: 1.09,
        Hephaestanite: 1,
        Iron: 1,
        Laranite: 0.99,
        Quantainium: 1.01,
        Quartz: 1,
        Taranite: 1,
        Titanium: 1.06,
        Tungsten: 1.09,
      },
    },
    "MIC L5": {
      name: "Modern Icarus Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 1.07,
        Bexalite: 1.12,
        Borase: 1.09,
        Copper: 1,
        Corundum: 1,
        Gold: 1,
        Hephaestanite: 1.08,
        Iron: 1.08,
        Laranite: 1,
        Quantainium: 1,
        Quartz: 1,
        Taranite: 1,
        Titanium: 1.13,
        Tungsten: 1,
      },
    },
    "STA-MAG": {
      name: "Magnus Jump Gateway Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 0.95,
        Beryl: 1.07,
        Bexalite: 1,
        Borase: 1,
        Copper: 1,
        Corundum: 0.96,
        Gold: 1,
        Hephaestanite: 0.96,
        Iron: 1.01,
        Laranite: 0.98,
        Quantainium: 1.03,
        Quartz: 1.11,
        Taranite: 0.94,
        Titanium: 1.05,
        Tungsten: 1,
      },
    },
    "STA-PYR": {
      name: "Magnus Gateway Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 1.07,
        Bexalite: 0.94,
        Borase: 1,
        Copper: 1,
        Corundum: 1.07,
        Gold: 0.94,
        Hephaestanite: 0.98,
        Iron: 1.02,
        Laranite: 0.92,
        Quantainium: 1,
        Quartz: 1,
        Taranite: 1,
        Titanium: 0.99,
        Tungsten: 1.02,
      },
    },
    "STA-TER": {
      name: "Terra Gateway Station",
      system: "Stanton",
      modifiers: {
        Agricium: 1.08,
        Aluminum: 1.07,
        Beryl: 0.94,
        Bexalite: 1,
        Borase: 1,
        Copper: 1.04,
        Corundum: 1.02,
        Gold: 1.01,
        Hephaestanite: 1,
        Iron: 1,
        Laranite: 1.02,
        Quantainium: 1,
        Quartz: 0.97,
        Taranite: 1,
        Titanium: 1,
        Tungsten: 1,
      },
    },
    "SOON TM": {
      name: "SOON TM",
      system: "Pyro",
      modifiers: {
        Agricium: 1,
        Aluminum: 1,
        Beryl: 1,
        Bexalite: 1,
        Borase: 1,
        Copper: 1,
        Corundum: 1,
        Gold: 1,
        Hephaestanite: 1,
        Iron: 1,
        Laranite: 1,
        Quantainium: 1,
        Quartz: 1,
        Taranite: 1,
        Titanium: 1,
        Tungsten: 1,
      },
    },
  },

  //if device is mobile, returns true
  detectDevice: () => {
    let device = "";

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      device = true;
    } else {
      device = false;
    }
    return device;
  },

  getModifierText: (mat, refinery) => {
    let refineryModifier =
      helpers.refineryStationModifiers[refinery]?.modifiers[mat] || 1;
    let result = Math.round(100 * refineryModifier) - 100;

    if (refineryModifier !== 1) {
      if (result > 0) {
        return `+${result}`;
      } else {
        return result;
      }
    }
  },

  //method for calculating value and amount of material based on all modifiers
  //(material, refinery method, amound in scu, refinery station, boolean for output type)
  calcValAndAmount: (mat, method, scu, refinery, isAmount) => {
    let methodModifier = helpers.refineryMethodModifiers[method];
    let refineryModifier =
      helpers.refineryStationModifiers[refinery]?.modifiers[mat] || 1;

    //if calculating amount of material
    if (isAmount) {
      return (methodModifier * refineryModifier * scu).toFixed(1);
      //if calculating value of materials
    } else {
      return Math.round(
        methodModifier * refineryModifier * helpers.scuValue[mat] * scu
      );
    }
  },

  evaluate: (mass, deposit, total) => {
    //mass of rock, all reported deposits, % inert

    //trim deposit down to non-zero inputs
    let reportedValues = Object.entries(deposit).filter(
      ([mat, val], i) => val > 0
    );

    //eval to solve for total volume (Mass / (sum of vol of each * density of each))
    //set base to be value for inert material
    let volxDensityAccumulator = (total / 100) * 19;

    reportedValues.map(([material, amount], i) => {
      //density of current material
      let currDensity = helpers.density[material];
      //(Volume% * Density)
      volxDensityAccumulator += (amount / 100) * currDensity;
    });

    //this is currently ignoring Inert Material
    let volumeTotal = mass / volxDensityAccumulator;

    //for each material return Volume Total * Volume Material = SCU of that material
    let result = {};

    reportedValues.forEach(([material, amount], i) => {
      result[material] = volumeTotal * (amount / 100);
    });

    //add inert to output obj
    result["Inert Material"] = volumeTotal * (total / 100);
    return result;
  },

  evalByRefinery: (deposit) => {
    delete deposit["Inert Material"];
    let refineryVals = {};
    //given deposits w/ scu amounts
    //calculate the refinery which provides the most return (highest multipliers)

    //calculate a value for each refinery
    //create new obj to store totalVals for each refinery
    for (let [loc, station] of Object.entries(
      helpers.refineryStationModifiers
    )) {
      refineryVals[loc] = {
        name: station.name,
        system: station.system,
        val: 0,
      };
    }

    //for every element in the deposit
    for (let [mat, scu] of Object.entries(deposit)) {
      //for every station
      for (let [loc, station] of Object.entries(refineryVals)) {
        let currModifier = helpers.refineryStationModifiers[loc].modifiers[mat];
        let currMaterialVal = helpers.scuValue[mat];
        //add value of current element to current station total
        station.val += Math.round(currModifier * scu * currMaterialVal);
      }
    }
    //return refinery w/ highest value
    return refineryVals;
  },

  getBestWorstRefineries: (refineryTotals, system) => {
    let currBest;
    let currWorst;

    Object.entries(refineryTotals).forEach(([loc, station], i) => {
      let currVal = station.val;

      //ignore refineries not in current system
      if (station.system !== system) {
        return;
      }

      //1st element or no currBest/Worst set yet
      if (i === 0 || !currBest || !currWorst) {
        currBest = { loc, station };
        currWorst = { loc, station };
      }
      if (currVal > currBest.station.val) {
        currBest = { loc, station };
      }
      if (currVal < currWorst.station.val) {
        currWorst = { loc, station };
      }
    });

    let recObj = { best: currBest, worst: currWorst };
    return recObj;
  },

  formatNum: (number) => {
    //adds commas to numbers
    return number.toLocaleString();
  },
};

export default helpers;
