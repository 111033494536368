import "./App.css";
import React, { useState, useEffect } from "react";
import DiscordOffCanvas from "./DiscordOffCanvas.jsx";
import DepositCalculator from "./DepositCalculator.jsx";
import PriceCalculator from "./PriceCalculator.jsx";
import { UseContextAll } from "./ContextAll";

function App() {
  const { display, setDisplay } = UseContextAll();

  //trying to force render of drop downs with effect (cant reset w/ button after page change)
  // useEffect(() => {
  //   console.log("display changed");
  //   console.log(formState);
  //   // setFormState()
  // }, [display]);

  return (
    <div className="siteContainer">
      <div>
        <nav className="navbar">
          <img className="synLogo" src="/SynLogo.png" alt="Syn Logo"></img>
          <h3 className="titleText">Syndicus Mining Utility</h3>
          <ul className="navList">
            <li
              className={`navItem ${
                display === "deposit calculator" ? "navActive" : ""
              }`}
              id="deposit calculator"
              onClick={(e) => setDisplay(e.target.id)}
            >
              Assay Tool
            </li>
            <li
              className={`navItem ${
                display === "price calculator" ? "navActive" : ""
              }`}
              id="price calculator"
              onClick={(e) => setDisplay(e.target.id)}
            >
              Price Tool
            </li>
          </ul>
        </nav>
        <div className="App">
          {display === "deposit calculator" ? <DepositCalculator /> : ""}
          {display === "price calculator" ? <PriceCalculator /> : ""}
        </div>
        <div className="discordContainer">
          <DiscordOffCanvas />
        </div>
      </div>
      <footer className="closeBlurb">
        <p>
          This application was created by TukTukBeepBeep of Syndicus Astral
          Intelligence. Additional invaluable support from Deathsquadie, the
          rock wizard, and Tazshalis, the maths wizard.
        </p>
      </footer>
    </div>
  );
}

export default App;

/*

potential bugs:

  mass showing up as NaN when typed in for some users
  potentially an issue w/ toLocale, maybe pull locale string type to see if a user is meant to use "," or "." in their numbers. (removed feature)

  drop down elements are not resetting after reset btn, state is updated but the element is not updated (removed state reset to avoid disagreement between dom elements and state)

potential additions:
  add unrefined sell values rather than 'unrefined'

  maybe add tab for scouting utility?

  faq / how to guide?

  refactor the refinery select elements. currently i have one select el for each system. i could add filters to the option elements instead. either way i should make this into its own sub components, i think.
*/
