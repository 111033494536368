import React from "react";
import helpers from "./helpers.js";
import { UseContextAll } from "./ContextAll";

const Output = ({
  handleIsRefined,
  updateRefinery,
  updateSystem,
  updateMethod,
}) => {
  const {
    isRefined,
    formState,
    assay,
    refinery,
    system,
    method,
    refineryRecs,
  } = UseContextAll();

  return (
    <div className="dashboard">
      <div className="outputContainer">
        <div className="refineryOptionsContainer">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={handleIsRefined}
              checked={isRefined ? true : false}
            ></input>
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Refined
            </label>
          </div>
          <form className="select-wrapper-refinery-and-method">
            <select
              className="form-select form-control refineryMethod"
              placeholder="Refinery Method"
              onChange={(e) => updateMethod(e.target.value)}
              defaultValue={formState.method}
            >
              <option value="Dinyx Solvention">Dinyx Solvention</option>
              <option value="Ferron Exchange">Ferron Exchange</option>
              <option value="Cormack Method">Cormack Method</option>
              <option value="Electrostarolysis">Electrostarolysis</option>
              <option value="Pyrometric">Pyrometric</option>
              <option value="Gaskin Process">Gaskin Process</option>
            </select>
            {isRefined && (
              <select
                className="form-select form-control systemSelect"
                defaultValue={formState.system}
                onChange={(e) => updateSystem(e.target.value)}
              >
                <option value="Stanton">Stanton</option>
                <option value="Pyro">Pyro</option>
              </select>
            )}
            {isRefined && system === "Stanton" && (
              <select
                className="form-select form-control refineryMethod"
                defaultValue={formState.refinery}
                onChange={(e) => updateRefinery(e.target.value)}
              >
                <option value="--Select Refinery--">--Select Refinery--</option>
                <option value="HUR L1">HUR L1</option>
                <option value="HUR L2">HUR L2</option>
                <option value="CRU L1">CRU L1</option>
                <option value="ARC L1">ARC L1</option>
                <option value="ARC L2">ARC L2</option>
                <option value="ARC L4">ARC L4</option>
                <option value="MIC L1">MIC L1</option>
                <option value="MIC L2">MIC L2</option>
                <option value="MIC L5">MIC L5</option>
                <option value="STA-MAG">STA-MAG</option>
                <option value="STA-PYR">STA-PYR</option>
                <option value="STA-TER">STA-TER</option>
              </select>
            )}
            {isRefined && system === "Pyro" && (
              <select
                className="form-select form-control refineryMethod"
                defaultValue={formState.system}
                onChange={(e) => updateRefinery(e.target.value)}
              >
                <option value="--Select Refinery--">--Select Refinery--</option>
                <option value="SOON TM">SOON TM</option>
              </select>
            )}
          </form>
        </div>
        {Object.entries(assay).map(([mat, scu], i) => {
          return (
            <div
              className={`individualOutputWrapper ${
                i % 2 === 0 ? "lineLight" : "lineDark"
              } ${
                helpers.getModifierText(mat, refinery) > 0
                  ? "positive"
                  : helpers.getModifierText(mat, refinery) < 0
                  ? "negative"
                  : ""
              }`}
              key={i}
            >
              <div
                className={`individualOutputMat ${
                  ["Taranite", "Bexalite", "Gold", "Quantainium"].includes(mat)
                    ? "tier1"
                    : ""
                }`}
              >
                {`${mat}`}
              </div>
              <p>{`${
                helpers.getModifierText(mat, refinery)
                  ? helpers.getModifierText(mat, refinery) + "%"
                  : ""
              }`}</p>
              <div className="individualOutputScu">
                {`${
                  isRefined
                    ? helpers.calcValAndAmount(mat, method, scu, refinery, true)
                    : scu.toFixed(1)
                }`}
                <small> SCU</small>
              </div>
              <div className="individualOutputVal">
                {isRefined
                  ? `${helpers.formatNum(
                      helpers.calcValAndAmount(
                        mat,
                        method,
                        scu,
                        refinery,
                        false
                      )
                    )} `
                  : ""}
                <small>{isRefined ? " aUEC" : "unrefined"}</small>
              </div>
            </div>
          );
        }) || ""}
      </div>

      {refineryRecs.best?.loc ? (
        <div className="refineryRecContainer">
          <div className="refineryRecWrapper">
            <p className="recTitle">Recommended Refinery:</p>
            <p>{`${refineryRecs.best.station.name} at ${
              refineryRecs.best.loc
            } for ${helpers.formatNum(
              Math.round(
                refineryRecs.best.station.val *
                  helpers.refineryMethodModifiers[method]
              )
            )} aUEC`}</p>
          </div>

          <div className="refineryRecWrapper">
            <p className="recTitle">Worst Refinery:</p>
            <p>{`${refineryRecs.worst.station.name} at ${
              refineryRecs.worst.loc
            } for ${helpers.formatNum(
              Math.round(
                refineryRecs.worst.station.val *
                  helpers.refineryMethodModifiers[method]
              )
            )} aUEC`}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Output;
