import React from "react";
import { UseContextAll } from "./ContextAll";

const MaterialInput = ({ currMat, style, updateRock }) => {
  const { total, deposit } = UseContextAll();
  return (
    <>
      {currMat === "Inert Material" ? (
        <div className="singleMaterialContainer lineLight">
          {" "}
          <span>{currMat}</span>
          {total}
        </div>
      ) : (
        <div
          className={`singleMaterialContainer ${
            style === "light" ? "lineLight" : "lineDark"
          } ${
            ["Quantainium", "Bexalite", "Gold", "Taranite"].includes(currMat)
              ? "tier1"
              : ""
          } ${
            [
              "Borase",
              "Laranite",
              "Hephaestanite",
              "Agricium",
              "Beryl",
            ].includes(currMat)
              ? "tier2"
              : ""
          }`}
        >
          <span>{currMat}</span>
          <input
            type="text"
            inputMode="numeric"
            className="materialPercentInput"
            onChange={(e) => updateRock(currMat, e.target.value)}
            value={deposit[currMat] > 0 ? deposit[currMat] : ""}
          ></input>
        </div>
      )}
    </>
  );
};

export default MaterialInput;
