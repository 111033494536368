import React, { useState, useEffect } from "react";
import { GiMineWagon } from "react-icons/gi";
import Input from "./Input.jsx";
import Output from "./Output.jsx";
import helpers from "./helpers.js";
import { UseContextAll } from "./ContextAll";

const DepositCalculator = () => {
  const {
    deposit,
    setDeposit,
    mass,
    setMass,
    total,
    setTotal,
    setRefineryRecs,
    setAssay,
    system,
    setSystem,
    setMethod,
    formState,
    setFormState,
    isRefined,
    setIsRefined,
    setRefinery,
    handleDepositReset,
  } = UseContextAll();

  //an animation before evaluation, for funsies
  const evalAnimate = () => {
    let btn = document.querySelector(".btn-mine");
    btn.classList.add("btn-mine-animation");
    let isMobile = helpers.detectDevice();

    setTimeout(() => {
      //run evaluation, stop animation, and scroll to output
      evaluateRock();
      btn.classList.remove("btn-mine-animation");
      //if on mobile, scroll to output
      if (isMobile) {
        document.querySelector(".outputContainer").scrollIntoView(true);
      }
    }, 500);
  };

  //evaluate user submitted deposit
  const evaluateRock = () => {
    //to make sure reported values make sense
    let count = 0;

    for (let val of Object.values(deposit)) {
      if (val < 0 || val > 100 || isNaN(val)) {
        alert("Error! Reported Values Invalid");
      }
      count += val;
      if (count > 100) {
        alert("Error! Math :(");
        return;
      }
    }

    //commented code block from when ',' were being inserted into mass number automatically
    ///////////////////////////////
    //make sure mass is valid
    // let massArr = [...mass];
    // let massNum;

    // countries that use "," as separator
    // if (massArr.includes(",")) {
    //   massNum = mass ? Number(mass.replaceAll(",", "")) : 0;
    // }
    //countries that use "." as separator
    // if (massArr.includes(".")) {
    //   massNum = mass ? Number(mass.replaceAll(".", "")) : 0;
    // }

    // if (!Number(massNum) || Number(massNum) <= 0) {
    //   alert("Error! Rock Mass Invalid");
    // }

    //evaluate deposit
    // let result = helpers.evaluate(massNum, deposit, total);
    ///////////////////////////////

    if (!Number(mass) || Number(mass) <= 0) {
      alert("Error! Rock Mass Invalid", mass);
    }

    //eval rock
    let result = helpers.evaluate(mass, deposit, total);
    //get all station vals based on rock
    let refineryTotals = helpers.evalByRefinery(result);
    //get recommended stations based on all station vals & system
    let recommendations = helpers.getBestWorstRefineries(
      refineryTotals,
      system
    );
    setRefineryRecs(recommendations);
    setAssay(result);
  };

  //update current rock
  const updateRock = (material, val) => {
    let rockCopy = { ...deposit };
    rockCopy[material] = Number(val);
    setDeposit(rockCopy);
  };

  //update current rock mass
  const updateMass = (val) => {
    // console.log([...val]);
    // let formatted;

    //original
    // let formatted = helpers.formatNum(Number(val.replaceAll(",", "")));

    //accounting for intl
    // if ([...val].includes(",")) {
    //   formatted = helpers.formatNum(Number(val.replaceAll(",", "")));
    // }
    // if ([...val].includes(".")) {
    //   formatted = helpers.formatNum(Number(val.replaceAll(".", "")));
    // }
    // setMass(formatted);
    setMass(val.toString().replaceAll(",", ""));
  };

  //update system
  const updateSystem = (val) => {
    setSystem(val);

    //update state of default value
    let copy = { ...formState };
    copy.system = val;
    setFormState(copy);
    setRefinery("");
  };

  //update refinery method
  const updateMethod = (val) => {
    setMethod(val);
    //update state of default value
    let copy = { ...formState };
    copy.method = val;
    setFormState(copy);
  };

  //update values for if refined or raw
  const handleIsRefined = () => {
    //if turning switch off, reset refinery and dom el for refinery select
    if (isRefined) {
      setSystem("Stanton");
      setRefinery("");
      setMethod("Dinyx Solvention");
      setFormState({
        system: "Stanton",
        method: "Dinyx Solvention",
        refinery: "--Select Refinery--",
      });
      document.querySelector(".select-wrapper-refinery-and-method").reset();
    }
    setIsRefined(!isRefined);
  };

  //update which refinery modifiers are in place
  const updateRefinery = (val) => {
    setRefinery(val);
    //update state of default value
    let copy = { ...formState };
    copy.refinery = val;
    setFormState(copy);
  };

  //effect for updating inert amount automatically
  useEffect(() => {
    let amountInert = 0;
    for (let val of Object.values(deposit)) {
      amountInert += val;
    }
    let newTotal = 100 - amountInert;
    setTotal(newTotal);
  }, [deposit]);

  //effect for formatting mass input automatically
  useEffect(() => {
    document.querySelector(".massInputField").value = mass || "";
  }, [mass]);

  return (
    <div className="appContainer">
      <div className="componentWrapper">
        <Input
          updateRock={updateRock}
          evaluateRock={evaluateRock}
          updateMass={updateMass}
          handleDepositReset={handleDepositReset}
        />
        <div className="assayBtnContainer">
          <p>Assay!</p>
          <GiMineWagon className="btn-mine" onClick={() => evalAnimate()} />
        </div>
        <div className="output-title-container">
          <Output
            handleIsRefined={handleIsRefined}
            updateRefinery={updateRefinery}
            updateSystem={updateSystem}
            updateMethod={updateMethod}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositCalculator;
