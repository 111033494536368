import React from "react";
import { BiReset } from "react-icons/bi";
import helpers from "./helpers.js";
import MaterialInput from "./materialInput.jsx";

const Input = ({
  updateRock,
  evaluateRock,
  updateMass,
  handleDepositReset,
}) => {
  return (
    <div className="dashboard rock-input-dashboard">
      <BiReset className="resetIcon" onClick={() => handleDepositReset()} />

      <div className="massInputWrapper">
        <label htmlFor="massinput">Mass: </label>
        <input
          className="massInputField"
          type="text"
          id="massinput"
          inputMode="numeric"
          placeholder="Mass of Rock"
          onChange={(e) => updateMass(e.target.value)}
        ></input>
      </div>
      <div className="materialListContainer">
        <div className="bestResultsPara">
          <p>
            For <i>best</i> results, provide values for all materials.
          </p>
        </div>
        {helpers.materials.map((material, i) => {
          return (
            <MaterialInput
              currMat={material}
              style={i % 2 === 0 ? "light" : "dark"}
              updateRock={updateRock}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Input;
