import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { BiReset, BiInfoCircle } from "react-icons/bi";
import helpers from "./helpers.js";
import { UseContextAll } from "./ContextAll";

const PriceCalculator = () => {
  const {
    curMats,
    setCurMats,
    checkBoxState,
    setCheckBoxState,
    SCU,
    setSCU,
    ifSaddleBags,
    setIfSaddleBags,
    yieldEstimateModifier,
    setYieldEstimateModifier,
    totalVal,
    setTotalVal,
    handlePriceReset,
  } = UseContextAll();

  //when an element is selected, add to list
  const handleElementSelect = (val, i) => {
    let checkBoxCur = { ...checkBoxState };
    let elements = [...curMats] || [];
    //if not in list, add it
    if (!elements.includes(val)) {
      elements.push(val);
      //save "checked" state of that button
      checkBoxCur[i] = true;
      //if in list, remove it
    } else {
      elements = elements.filter((el) => el !== val);
      //remove "checked" state of that button
      checkBoxCur[i] = false;
    }
    setCurMats(elements);
    setCheckBoxState(checkBoxCur);
  };

  //keep track of user submitted scu for each material
  const handleScuUpdate = (mat, val) => {
    let scuCopy = { ...SCU };
    scuCopy[mat] = { amount: val, price: 0 };
    setSCU(scuCopy);
  };

  //create obj w/ all relevant info for rendering
  const handleValCalc = () => {
    let value = 0;
    let scuCopy = { ...SCU };

    //for every element the user selected
    for (let [mat, props] of Object.entries(scuCopy)) {
      //if amount of scu is invalid, error
      if (isNaN(props.amount) || props.amount < 0)
        return alert("Error, Invalid Entry");

      //if pre refinery (apply multiplier)
      if (ifSaddleBags) {
        props.price =
          helpers.scuValue[mat] *
          props.amount *
          helpers.refineryMethodModifiersSimple[yieldEstimateModifier];
        value += props.price;
      } else {
        //if post refinery (full value)
        props.price = helpers.scuValue[mat] * props.amount;
        value += props.price;
      }
    }

    setSCU(scuCopy);
    setTotalVal(helpers.formatNum(Math.round(value)));
  };

  //toggle if estimate based on pre or post refinery (bool)
  const toggleSaddleBagCalc = () => {
    setIfSaddleBags(!ifSaddleBags);
  };

  //when user changes yield modifier (h/m/l), update estimate
  useEffect(() => {
    handleValCalc();
  }, [yieldEstimateModifier]);

  return (
    <div className="priceToolWrapper">
      <div className="dashboard">
        <BiReset className="resetIcon" onClick={() => handlePriceReset()} />
        <BiInfoCircle className="infoIcon" size={25} />
        <div className="hiddenInfo">
          Check "Saddle Bag Estimate" if you want to get SCU & value of the
          material in your mining ship. This applies a refinery modifier to your
          load.
          <br></br>
          <br></br>
          If you have SCU's already, leave this unchecked.
        </div>
        <div className="priceTool-InputButtonWrapper">
          <Form className="priceTool-SaddleBagToggle">
            <Form.Check
              reverse
              label="Saddle Bag Estimate?"
              name="saddleBagToggle"
              type="checkbox"
              id="checkbox-saddleBagToggle"
              checked={ifSaddleBags}
              onChange={() => toggleSaddleBagCalc()}
            />
            <div className="hiddenInfoContainer"></div>
            {ifSaddleBags && (
              <div className="priceTool-yieldSelectWrapper">
                <label htmlFor="priceTool-yieldSelect">Efficiency</label>
                <select
                  className="form-select form-control priceTool-yieldSelect"
                  id="priceTool-yieldSelect"
                  defaultValue="Yield Efficiency"
                  onChange={(e) => setYieldEstimateModifier(e.target.value)}
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
            )}
          </Form>

          <div className="priceTool-checkContainer">
            {helpers.materials.map((mat, i) => {
              return (
                <div
                  className={`form-check ${
                    mat === "Inert Material" ? "hidden" : ""
                  }`}
                  key={i}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={mat}
                    name="material-checkbox"
                    checked={checkBoxState[i] ? "checked" : ""}
                    onChange={(e) => handleElementSelect(e.target.id, i)}
                  ></input>
                  <label
                    className={`form-check-label ${
                      ["Quantainium", "Bexalite", "Gold", "Taranite"].includes(
                        mat
                      )
                        ? "tier1"
                        : ""
                    } ${
                      [
                        "Borase",
                        "Laranite",
                        "Hephaestanite",
                        "Agricium",
                        "Beryl",
                      ].includes(mat)
                        ? "tier2"
                        : ""
                    }`}
                    htmlFor="flexCheckDefault"
                  >
                    {mat}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="priceTool-scuListContainer">
            {curMats.map((mat, i) => {
              return (
                <div
                  className={`priceTool-scuList ${
                    i % 2 === 0 ? "lineLight" : "lineDark"
                  } `}
                  key={i}
                >
                  <label htmlFor="priceToolSCU">{mat}</label>
                  <input
                    className="priceTool-scuInput"
                    id="priceToolSCU"
                    type="text"
                    value={SCU[mat]?.amount}
                    inputMode="numeric"
                    placeholder="scu"
                    onChange={(e) => handleScuUpdate(mat, e.target.value)}
                  ></input>
                  <div className="priceTool-outputPriceWrapper">
                    {SCU[mat]?.price
                      ? `${helpers.formatNum(Math.round(SCU[mat].price)) || ""}`
                      : ""}
                    <small>{SCU[mat]?.price ? " aUEC" : ""}</small>
                  </div>
                </div>
              );
            })}
          </div>
          <button onClick={() => handleValCalc()}>Calculate!</button>
        </div>
        <div className="priceTool-totalValContainer">
          <h2 className="priceTool-totalVal">{totalVal}</h2>
          {totalVal ? <small>aUEC</small> : ""}
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
